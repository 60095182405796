[data-amplify-authenticator] [data-amplify-router] {
  background-color: unset;
  box-shadow: unset;
  border-color: unset;
  border-width: unset;
  border-style: unset;
}

.amplify-button[data-variation='primary'] {
  background-color: #1b79cc;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  &:hover {
    background-color: rgb(24, 105, 177);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
}